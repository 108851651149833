* {
  box-sizing: border-box;
  /* font-family: "Poppins", sans-serif; */
  font-family: "Raleway", cursive;
  /* color: #3f3f3f; */
  letter-spacing: 0.5px;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Raleway", cursive;
  font-size: 1.2rem;
  line-height: 1.5;
  color: #000;
}

body::-webkit-scrollbar {
  display: none;
}

.nav-shadow {
  box-shadow: 0 0 5px 2px #6d6c6c71 !important;
  transition: all 0.3s ease-in !important;
  padding-top: 0.1rem !important;
  padding-bottom: 0.1rem !important;
}

.shadow-0 {
  box-shadow: none !important;
  transition: all 0.3s ease-in !important;
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}
.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999;
  box-shadow: 0 0 5px 2px #6d6c6c71 !important;
  background-color: #ffffff !important;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  padding-top: 0px;
  padding-bottom: 0px;
}
.fixed-nav {
  position: fixed !important;
  animation: animate-nav 2s forwards !important;
  width: 100%;
  box-shadow: 0 0 5px 2px #6d6c6c71 !important;
}

.remove-fixed-nav {
  position: fixed !important;
  animation: animate-nav-remove 2s forwards !important;
  width: 100%;
  box-shadow: 0 0 5px 2px #6d6c6c71 !important;
}

@keyframes animate-nav {
  0% {
    top: -100px;
    left: 0;
  }
  100% {
    top: 0;
    left: 0;
  }
}

@keyframes animate-nav-remove {
  0% {
    top: 0;
    left: 0;
  }
  100% {
    top: -100px;
    left: 0;
  }
}

.curly_text {
  font-family: "Cookie", cursive;
}

.brown_color {
  color: #8c071b;
}
a:active {
  color: #373737 !important;
}
.bgbrown_color {
  background-color: #8c071b;
}
.bgcreem_color {
  background-color: #f8f5f0;
}

.section_padding {
  padding: 2rem 0;
}
.section_heading h1 {
  font-size: 3rem;
  font-family: "Cookie", cursive;
  /* color: #373737; */
  font-weight: 600;
  color: #8c071b;
  letter-spacing: 2px;
  line-height: 0.7;
}
.diff {
  font-size: 4rem;
  font-family: "Cookie", cursive;
  /* font-weight: 600; */
  color: #8c071b;
  letter-spacing: 2px;
}

.sub_menu {
  letter-spacing: 2px;
}
/* .section_heading .diff {
  font-size: 4rem;
  color: #8C071B;
  font-family: "Cookie", cursive;
  letter-spacing: 3px;
} */

.banner_img {
  /* align-items: center; */
  background-image: url(/public/Sources/images/a/2.png);
  background-position: 42%;
  background-repeat: no-repeat, repeat;
  background-size: cover;
  /* display: flex; */
  height: 63vh;
  /* justify-content: center; */
  /* position: relative; */
}
.img_text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 2rem;
  font-size: 2.2rem;
  font-weight: 500;
}
.image_wrapper {
  overflow: hidden;
}
.about_image {
  transition: 0.3s;
}

.about_image:hover {
  transform: scale(1.1);
}
.img-wrapper {
  display: inline-block;
  box-sizing: border-box;
}

.main_slide {
  width: 100%;
}

/* .carousel .thumbs-wrapper {
  display: none;
}
.carousel .carousel-status {
  display: none;
} */
.allslider {
  height: 85vh;
}

/* header start */
.get_in {
  /* border-color: #373737 !important; */
  letter-spacing: 2px;
  border-color: #8c071b !important;
  background: #fff !important;
  color: #8c071b !important;
}
.get_in:hover {
  color: #8c071b !important;
  border-color: #8c071b !important;
  background: #8c071b !important;
  color: #fff !important;
  transition: all 0.5s ease-in-out;
}

.header_item {
  margin: 1.7rem 2rem;
  letter-spacing: 0.5px;
  padding: 0px !important;
  color: #000;
  line-height: 3;
  font-weight: 600;
  transition: all 0.4s;
}

.navigation {
  position: relative;
}
.borderXwidth .hoverheader_item:before,
.borderXwidth .hoverheader_item:after {
  position: absolute;
  opacity: 0;
  width: 0%;
  height: 2px;
  content: "";
  background: #8c071b;
  transition: all 0.6s;
}

.borderXwidth .hoverheader_item:before {
  left: 0px;
  top: 0px;
}

.borderXwidth .hoverheader_item:after {
  right: 0px;
  bottom: 0px;
}

.borderXwidth .hoverheader_item:hover:before,
.borderXwidth .hoverheader_item:hover:after {
  opacity: 1;
  width: 100%;
}

.button_item {
  margin: 0;
}
.navbar-item img {
  max-height: 6.75rem;
}
.navbar-burger:hover {
  background-color: #fff;
}
.navbar-item img {
  max-height: 5rem;
}
a.navbar-item:focus,
a.navbar-item:focus-within,
a.navbar-item:hover,
a.navbar-item.is-active,
.navbar-link:focus,
.navbar-link:focus-within,
.navbar-link:hover,
.navbar-link.is-active {
  background-color: #fff;
  color: #8c071b;
}
.header_button {
  line-height: 5.5;
}
.navbar-menu.is-active {
  display: block;
  position: fixed;
  width: 100%;
}
/* header end */

/* breadcrumb start */
.bread_crumb {
  background-image: url(/public/Sources/images/splash/3.png);
  background-position: bottom;
  background-size: cover;
  background-blend-mode: multiply;
  background-repeat: round;
  opacity: 1;
  background-color: #0000006b;
}
/* breadcrumb end */

/* room details start */
.Junior_Suite {
  padding-right: 5rem;
}
/* room details end */

/* room start */

.room_facilities {
  display: flex;
  align-items: center;
}
.roomprice_sub {
  line-height: 1;
  padding-left: 1.5rem;
  letter-spacing: 1px;
}
.roomcard_wrapper1 {
  padding: 2.5rem;
  margin: 1rem;
  background: #fff;
  transform: translateX(112%);
  /* border: 1px solid #8c071b3d; */
  /* border-right: 1px solid #8c071b;
  border-bottom: 1px solid #8c071b;
  border-top: 1px solid #8c071b; */
  text-align: justify;
  z-index: 9;
}
.roomcard_wrapper2 {
  padding: 2.5rem;
  margin: 1rem;
  background: #fff;
  transform: translateX(-12%);
  /* border: 1px solid #8c071b3d; */
  /* border-right: 1px solid #8c071b;
  border-bottom: 1px solid #8c071b;
  border-top: 1px solid #8c071b; */
  text-align: justify;
  z-index: 9;
}
.roomcard_image {
  transition: all 0.7s;
}
.roomcard_image:hover {
  transform: scale(0.98);
  transition: all 0.7s;
}
/* room end */

/* service start */
.our_services {
  display: flex;
  justify-content: center;
}

.service_wrapper {
  padding: 2rem;
  /* border: 1px solid #33333338; */
  margin: 1.5rem;
  display: flex;
  justify-content: center;
  box-shadow: 0 -1px 1px #0000001f, 0 0px 1px #000000c7;
  transition: all 0.6s;
  /* min-height: 270px; */
}
.service_wrapper:hover {
  /* border: 0px solid #33333338; */
  box-shadow: 2px 2px 20px #00000040;
  transition: all 0.6s;
}
.service_wrapper:hover .service_icon {
  animation: mymove 2s forwards;
}

@keyframes mymove {
  to {
    transform: rotateY(360deg);
  }
}
.diff2 {
  font-size: 3rem;
  font-family: "Cookie", cursive;
  /* font-weight: 600; */
  /* color: #8c071b; */
  letter-spacing: 2px;
}

/* service end */

/* below service start */

/* .service_wrapper_facilities {
  position: relative;
  padding: 2rem;
  border: 1px solid #33333338;
  margin: 1.5rem;
  display: flex;
  justify-content: center;
  box-shadow: 0 -1px 1px #0000001f, 0 0px 1px #000000c7;
  transition: all 0.6s;
  min-height: 270px;
} */
.below_service {
  padding: 3rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.image_wrapper {
  overflow: hidden;
}
.service_img {
  transition: 0.3s;
}

.service_img:hover {
  transform: scale(1.1);
}
/* below service end */

/* local activities start */
.activitybox_wrapper {
  padding: 1.5rem;
}
.activity_img img {
  position: relative;
  width: 100%;
  height: 100%;
}

.content {
  position: absolute;
  bottom: 10%;
  top: 10%;
  left: 50%;
  right: 10%;
  background-color: #00000080;
  overflow: hidden;
  width: 0;
  height: auto;
  transition: 0.5s ease;
}

.activity_img:hover .content {
  width: 80%;
  left: 10%;
  right: 10%;
}

.text {
  position: absolute;
  top: 40%;
  left: 50%;
  opacity: 0.1;
  color: white;
  font-size: 20px;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  transition: 0.5s ease;
}
.activity_img:hover .text {
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 1;
  transition: 0.5s ease;
}
/* local activities end */

/* card start */
.card_formates .card_formate {
  box-shadow: 0 0 5px 2px #6d6c6c71 !important;
  position: relative;
  margin-bottom: 3.5rem;
}

.ami_content {
  padding: 130px;
}

.card_formate .textContainer {
  padding: 30px;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.about_image {
  width: 100%;
}

.move_img {
  /* align-items: center; */
  background-image: url(/public/Sources/images/splash/1.png);
  /* display: flex; */
  height: 50vh;
  /* justify-content: center; */
  /* position: relative; */
  /* background-attachment: fixed; */
  background-position: center;
  /* background-repeat: no-repeat; */
  background-size: cover;
}
/* .div_images {
  border-radius: 15px;
} */
/* card end */
/* aboutus page aminities card start */
.amenities .card_formate {
  margin: 5.5rem 0;
}
.amenities .card_formates .card_formate {
  box-shadow: 0 0 5px 2px #6d6c6c71 !important;
  position: relative;
  margin-bottom: 3.5rem;
}

.amenities .ami_content {
  padding: 130px;
}

.amenities .card_formate .textContainer {
  padding: 30px;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.amenities .about_image,
.amenities .about_image_main {
  width: 100%;
}

.amenities .move_img {
  align-items: center;
  background-image: url(/public/Sources/images/splash/1.png);
  display: flex;
  height: 50vh;
  justify-content: center;
  position: relative;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.amenities .img_height {
  height: 100%;
  padding: 0 1rem;
}

/* aboutus page aminities card end */

/* Restaurant start */
.breadcrumbs {
  background-image: url("/public/Sources/images/food/1.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.breadcrumbs_padding {
  padding: 5rem 0;
}
.line {
  width: 100%;
  background-color: #8c071b;
  height: 3px;
  margin: 1rem 0;
}
.food_wrapper {
  position: relative;
}
.food_img {
  width: 120px;
  height: 120px;
  position: absolute;
  bottom: -54px;
  right: 0;
  border-radius: 60px 60px 0;
}
.food_divider {
  margin-top: 7rem;
}

/* Restaurant end */

/* experiences start */
.inner_permalink {
  color: #fff;
}
.inner_permalink:hover {
  color: #fff;
}
.item .near_line {
  text-align: center;
  height: 1px;
  width: 60px;
  background-color: #ffffffab;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  margin: auto 0 30px;
}
.item:hover .near_line {
  width: 100%;
  transition-property: all;
  transition-duration: 0.8s;
  transition-timing-function: ease-in-out;
}
.item {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}

.item:hover .con {
  bottom: 0;
}
.item .con {
  padding: 20px;
  position: absolute;
  bottom: -60px;
  left: 0;
  width: 100%;
  transition: all 0.6s;
  text-align: left;
  z-index: 20;
  height: auto;
  box-sizing: border-box;
  background: linear-gradient(
    to bottom,
    transparent 0,
    #00000003 2%,
    #000000bf 90%
  );
}

.nearplace_img {
  overflow: hidden;
  transition: all 0.5s;
}
.nearplace_img img {
  overflow: hidden;
  transition: all 0.5s;
  width: 100%;
  height: 100%;
}
.item:hover .nearplace_img img {
  transform: scale(1.1, 1.1);
  transition: all 0.5s;
}
.picnic_content {
  text-align: center;
  /* padding-right: 2rem; */
}
/* experiences end */

/* welcome to paradise start */
.welcome_text {
  text-align: center;
}
/* welcome to paradise end */

/* contact start */
.getintouch {
  padding: 8rem;
}

/* contact end */

/* inquiry start */
.inquiry {
  padding: 2rem 8rem 8rem 8rem;
}
.citystate_desk {
  padding-right: 1.5rem;
}
.ant-input {
  padding: 10px 14px;
  border: 0;
  box-shadow: none;
  border-bottom: 1px solid #8c071b;
  color: #8c071b !important;
  font-size: 1.1rem;
}
.ant-input:hover {
  border-color: #8c071b;
}
.ant-input:focus,
.ant-input-focused {
  border-color: #8c071b;
  box-shadow: none;
}
/* inquiry end */

/* about us section start */
.image-3d-effect {
  position: relative;
}
.image-3d-effect .side.left {
  left: 0;
  width: 50%;
}
.image-3d-effect .side {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  z-index: 10;
}

.image-3d-effect .side.right {
  right: 0;
  width: 50%;
}
.image-3d-effect .side {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  z-index: 10;
}
.image-3d-effect .images {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}
.image-3d-effect .front_image {
  margin-right: 50px;
  z-index: 1;
  border-radius: 4px;
}
.image-3d-effect .back_image {
  margin-left: 100px;
  margin-top: -200px;
  background-position: bottom;
  border-radius: 4px;
}
.image-3d-effect .front_image,
.image-3d-effect .back_image {
  -webkit-perspective: 200px;
  perspective: 200px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  height: 300px;
  width: 500px;
  margin-right: 30px;
  background-size: cover;
  -webkit-box-shadow: 0 15px 75px 0 rgb(0 0 0 / 15%);
  box-shadow: 0 15px 75px 0 rgb(0 0 0 / 15%);
}
.image-3d-effect .side.right:hover ~ .images .front_image {
  -webkit-transform: matrix3d(
      0.94,
      0,
      0.34,
      0.0002,
      0,
      1,
      0,
      0,
      -0.34,
      0,
      0.94,
      0,
      1,
      0,
      10,
      1
    )
    translateX(30px);
  transform: matrix3d(
      0.94,
      0,
      0.34,
      0.0002,
      0,
      1,
      0,
      0,
      -0.34,
      0,
      0.94,
      0,
      1,
      0,
      10,
      1
    )
    translateX(30px);
}
.image-3d-effect .side.right:hover ~ .images .back_image {
  -webkit-transform: matrix3d(
      0.94,
      0,
      0.34,
      0.0005,
      0,
      1,
      0,
      0,
      -0.34,
      0,
      0.94,
      0,
      1,
      0,
      10,
      1.1
    )
    translateX(-100px);
  transform: matrix3d(
      0.94,
      0,
      0.34,
      0.0005,
      0,
      1,
      0,
      0,
      -0.34,
      0,
      0.94,
      0,
      1,
      0,
      10,
      1.1
    )
    translateX(-100px);
}
.image-3d-effect .side.left:hover ~ .images .front_image {
  -webkit-transform: matrix3d(
      0.94,
      0,
      0.34,
      -0.0002,
      0,
      1,
      0,
      0,
      -0.34,
      0,
      0.94,
      0,
      1,
      0,
      10,
      1
    )
    translateX(-10px);
  transform: matrix3d(
      0.94,
      0,
      0.34,
      -0.0002,
      0,
      1,
      0,
      0,
      -0.34,
      0,
      0.94,
      0,
      1,
      0,
      10,
      1
    )
    translateX(-10px);
}
.image-3d-effect .side.left:hover ~ .images .back_image {
  -webkit-transform: matrix3d(
      0.94,
      0,
      0.34,
      -0.00025,
      0,
      1,
      0,
      0,
      -0.34,
      0,
      0.94,
      0,
      1,
      0,
      10,
      1
    )
    translateX(30px);
  transform: matrix3d(
      0.94,
      0,
      0.34,
      -0.00025,
      0,
      1,
      0,
      0,
      -0.34,
      0,
      0.94,
      0,
      1,
      0,
      10,
      1
    )
    translateX(30px);
}

.about .img_height img {
  height: 100%;
  width: 100%;
}
/* section.about, */
section.experiences {
  background-color: #f8f5f0;
}
.about_text {
  padding: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.knowmore_button {
  border-color: #8c071b !important;
  letter-spacing: 3px;
}
.knowmore_button:hover {
  color: #fff !important;
  border-color: #8c071b !important;
  background: #f8f5f0 !important;
  color: #8c071b !important;
  font-weight: 600;
}

/* about us section end */

/* footer upper start */
.upper_footer {
  padding: 2rem 0;
}

.upper_footer .call_us {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.upper_footer .call_us .call_text {
  margin: 0 20px auto;
}

.upper_footer .call_us .call_text h4 {
  color: #fff;
}

.upper_footer .call_us .call_text h4 {
  color: #ffffff !important;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.03em;
}
.upper_footer h4 {
  line-height: 1;
  margin-bottom: 0;
}
.upper_footer .call_us .call_text h6 {
  color: #fff;
}
.upper_footer .call_us .call_text h6 {
  margin: 5px 0 0 0;
}
.upper_footer h6 {
  font-size: 0.95rem;
  font-weight: 400;
  line-height: 24px;
}
/* footer upper end */

/* footer start */
.main_footer {
  background-color: #f8f5f0;
}
.main_footer h3 {
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.03em;
}
.main_footer h3 {
  line-height: 1;
  margin-bottom: 0;
}
.foot_text {
  font-size: 1rem;
  line-height: 25px;
  color: #000;
}
.main_footer ul li a:hover {
  padding-left: 5px;
  font-weight: bold;
  color: #8c071b;
  transition: all 0.3s ease;
}
.footer_link {
  margin-bottom: 0.95rem;
  font-size: 1rem;
}
.first_col {
  margin-right: 2rem;
}
.second_col {
  margin-left: 2rem;
}
.second_col a {
  color: #000;
}
.third_col a {
  color: #000;
}

/* footer end */

/* lower footer start */
.lower_footer {
  padding: 1rem;
}
.lower_footer p {
  font-size: 1rem;
  text-align: center;
}
.lower_text a:hover {
  color: #000 !important;
}

/* lower footer end */

/* mobile start */
@media only screen and (max-width: 425px) {
  .mobile_margin {
    padding: 0 1rem;
  }
  .upper_footer {
    padding: 2rem 1rem;
  }
  .call_us {
    padding-bottom: 2rem;
  }
  .upper_footer h6 {
    font-size: 14px;
  }
  .call_img .icon {
    font-size: 1rem;
  }
  .first_col {
    margin-right: 0;
    margin-bottom: 1rem;
  }
  .second_col {
    margin-left: 0rem;
  }
  .ant-layout-footer {
    padding: 1.5rem;
  }
  .card_formate .textContainer {
    padding: 15px !important;
    margin: 0 !important;
    position: relative !important;
    top: 0% !important;
    transform: none !important;
  }
  .section_heading h1 {
    padding-bottom: 1.5rem;
  }
  .allslider {
    height: 10%;
  }
  /* welcome to paradise start */
  .welcome_text {
    text-align: justify;
  }
  /* welcome to paradise end */
  .about_text {
    position: relative;
  }

  .header_item {
    margin: 1rem 2rem 2.5rem;
  }
  .button_item {
    margin: 0.5rem 1rem;
  }
  .header_button {
    line-height: 0;
    margin: 1rem 2rem;
  }
  .header_item {
    line-height: 0;
  }

  .borderXwidth .hoverheader_item:before,
  .borderXwidth .hoverheader_item:after {
    position: relative;
    opacity: 0;
    width: 0%;
    content: "";
    background: none;
  }
  .getintouch {
    padding: 1rem;
  }
  .Junior_Suite {
    padding-right: 0;
  }
  .roomcard_wrapper1 {
    transform: none;
    padding: 0rem;
  }
  .roomcard_wrapper2 {
    transform: none;
    padding: 0rem;
  }
  .activity_text {
    text-align: left;
    padding-left: 0;
  }
  .local_activities {
    padding: 0 1rem;
  }
  .content {
    position: absolute;
    bottom: 19%;
    top: 21%;
  }
  .activity_img:hover .content {
    width: 70%;
    left: 15%;
    right: 10%;
  }
  .amenities .card_formate {
    margin: 0;
  }
  .picnic_content {
    text-align: justify;
    padding: 1rem;
  }
  .service_wrapper {
    margin: 1rem;
  }
  .below_service {
    padding: 2rem;
    position: inherit;
  }
  .caption {
    text-align: center;
    margin-top: 1rem;
  }
  .food_img {
    position: absolute;
    bottom: -17px;
  }
  .inquiry {
    padding: 1rem;
  }
  .citystate_desk {
    padding-right: 0;
  }
}
@media only screen and (max-width: 576px) {
}
/* mobile end */

/* tablet start */
@media only screen and (min-width: 426px) and (max-width: 769px) {
  .mobile_margin {
    padding: 0 1rem;
  }
  .upper_footer h6 {
    font-size: 12px;
  }
  .upper_footer {
    padding: 2rem 0.95rem;
  }
  .ant-layout-footer {
    padding: 1.2rem;
  }
  .first_col {
    margin-right: 1rem;
  }
  .second_col {
    margin-left: 0;
  }
  .about_image {
    width: 100%;
    height: 100%;
  }
  .card_formate .textContainer {
    padding: 15px;
    margin: 0;
    position: relative;
    top: 0%;
    transform: none;
  }
  .allslider {
    height: 42vh;
  }
  .about {
    padding: 1rem;
  }
  .about_text {
    position: relative;
  }

  .header_item {
    margin: 1rem 2rem 3rem;
  }
  .button_item {
    margin: 0.5rem 1rem;
  }
  .header_button {
    line-height: 0;
    margin: 0 2rem 1rem;
  }
  .header_item {
    line-height: 0;
  }
  .borderXwidth .hoverheader_item:before,
  .borderXwidth .hoverheader_item:after {
    position: relative;
    opacity: 0;
    width: 0%;
    content: "";
    background: none;
  }
  .getintouch {
    padding: 1.5rem;
  }
  .Junior_Suite {
    padding-right: 2rem;
  }
  .room_details {
    margin: 1rem;
  }
  .roomcard_wrapper1 {
    transform: none;
    margin: 0;
    padding: 0;
  }
  .roomcard_wrapper2 {
    transform: none;
    padding: 0;
    margin: 0;
  }
  .room_cards {
    margin: 2rem;
  }
  .local_activities {
    padding: 0 1rem;
  }
  .activity_text {
    text-align: left;
    padding-left: 0;
  }
  .activity_img:hover .content {
    width: 73%;
    left: 14%;
    right: 10%;
  }
  .content {
    position: absolute;
    bottom: 17%;
    top: 17%;
  }
  .picnic_content {
    text-align: justify;
    padding: 1rem;
  }
  .room_facilities {
    display: block;
  }
  .food_img {
    position: absolute;
    bottom: -17px;
  }
  .inquiry {
    padding: 2rem;
  }
}
@media only screen and (min-width: 526px) and (max-width: 768px) {
  .mobile_margin {
    padding: 0 1rem;
  }
  .below_service {
    padding: 2rem;
    position: inherit;
    top: 0;
    transform: translateY(0);
  }
  .amenities .card_formate .textContainer {
    position: inherit;
  }
  .allslider {
    height: 29vh;
  }
  .borderXwidth .hoverheader_item:before,
  .borderXwidth .hoverheader_item:after {
    position: relative;
    opacity: 0;
    width: 0%;
    content: "";
    background: none;
  }
}
/* tablet end */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .allslider {
    height: 52vh;
  }
  .second_col {
    margin-left: 0.2rem;
  }
  .Junior_Suite {
    padding-right: 4rem;
  }
  .roomcard_wrapper1 {
    padding: 2rem 2rem 2rem 0.2rem;
    margin: 1rem 0rem 1rem 1rem;
  }
  .roomcard_wrapper2 {
    padding: 2rem 0rem 2rem 2.2rem;
    margin: 1rem 0rem 1rem 1rem;
  }
  .activity_text {
    text-align: right;
    padding-left: 3rem;
  }
  .activity_img:hover .content {
    width: 75%;
    left: 12%;
    right: 12%;
  }

  .content {
    position: absolute;
    bottom: 17%;
    top: 17%;
  }
  .service_wrapper {
    margin: 0.5rem;
    min-height: 170px;
  }
  .roomcard_wrapper1 {
    transform: translateX(26%);
  }
  .getintouch {
    padding: 3rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .mobile_margin {
    padding: 0 1rem;
  }
  .allslider {
    height: 40vh;
  }
  .header_item {
    line-height: 1;
  }
  .borderXwidth .hoverheader_item:before,
  .borderXwidth .hoverheader_item:after {
    position: relative;
    opacity: 0;
    width: 0%;
    content: "";
    background: none;
  }
  .room_facilities {
    display: block;
  }
  .roomcard_wrapper1 {
    transform: translateX(0%);
  }
  .roomcard_wrapper2 {
    transform: translateX(0%);
  }
  .getintouch {
    padding: 2rem;
  }
}
.sidebar_contact {
  position: fixed;
  top: 350px;
  right: -256px;
  transform: translateY(-50%);
  width: 256px;
  height: auto;
  padding: 16px 30px;
  box-sizing: border-box;
  z-index: 9999999;
  font-size: 15px;
  transition: 0.5s;
  border-bottom-left-radius: 7px;
}
.toggle {
  position: absolute;
  height: 2.5rem;
  width: 6rem;
  text-align: center;
  cursor: pointer;
  background: #8c071b;
  top: 0;
  left: -6rem;
  line-height: 39px;
  border-radius: 5px 0 0 5px;
}
.toggle:before {
  content: "";
  font-family: fontAwesome;
  font-size: 18px;
  color: #fff;
  width: 20px;
}
.sidebar_contact p {
  color: #fff;
}
