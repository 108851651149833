.gallery_image {
  padding: 1.2rem;
}

@media only screen and (max-width: 425px) {
  .gallery_image {
    padding: 0.2rem;
  }
}

@media only screen and (min-width: 426px) and (max-width: 769px) {
  .gallery_image {
    padding: 0.75rem;
  }
}
